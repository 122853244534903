import noop from 'lodash/noop';
import React, { createContext, type PropsWithChildren, useState } from 'react';

import type { Event } from '~/types';

// Create Context Object
export const ListInteractionsContext = createContext<{
  highlightedMarker: string | null;
  selectedEvent: (Event & { eventKey: string }) | null;
  setSelectedEvent: React.Dispatch<React.SetStateAction<(Event & { eventKey: string }) | null>>;
  setHighlightedMarker: React.Dispatch<React.SetStateAction<string | null>>;
}>({ selectedEvent: null, highlightedMarker: null, setSelectedEvent: noop, setHighlightedMarker: noop });

// Create a provider for components to consume and subscribe to changes
export const ListInteractionsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [selectedEvent, setSelectedEvent] = useState<(Event & { eventKey: string }) | null>(null);
  const [highlightedMarker, setHighlightedMarker] = useState<string | null>(null);

  return (
    <ListInteractionsContext.Provider
      value={{
        selectedEvent,
        setSelectedEvent,
        highlightedMarker,
        setHighlightedMarker,
      }}
    >
      {children}
    </ListInteractionsContext.Provider>
  );
};
